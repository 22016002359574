<template>
    <v-row justify="center" class="py-5" align-content="center" style="height: 100%;">
        <v-col cols="12" sm="6">
            <v-card>
                <v-toolbar dense flat :color="primaryColor" dark>
                    <v-toolbar-title>Set up your profile</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-progress-linear :color="primaryColor" height="8" :value="progress" striped class="mb-6">
                </v-progress-linear>
                <template v-if="step === 'setup_loginfront' && isViewReady">
                    <v-card-text>
                        <p>We partner with LoginFront to protect access to your account. Follow the link below to set a password.</p>
                        <v-btn :style="primaryButtonStyle" @click="setupLoginFront" class="mt-4">Set up access protection</v-btn>
                        <!-- <p><a :to="{ name: 'user-dashboard' }">Skip remaining steps and proceed to dashboard</a></p> -->
                    </v-card-text>
                </template>
                <template v-if="step === 'setup_loginshield' && isViewReady">
                    <v-card-text>
                        <p>We partner with LoginShield&reg; to protect access to your account. Follow the link below to set a password, or go password-less with the LoginShield app.</p>
                        <!-- <p><a @click.prevent="setupLoginShield" href="#">Set up LoginShield</a></p> -->
                        <v-btn :style="primaryButtonStyle" @click="setupLoginShield" class="mt-4">Set up access protection</v-btn>
                        <!-- <p><a :to="{ name: 'user-dashboard' }">Skip remaining steps and proceed to dashboard</a></p> -->
                    </v-card-text>
                </template>
                <template v-if="step === 'create_account' && isViewReady">
                    <v-card-text>
                        <p>
                            Let's create an account and give it a name.
                        </p>
                        <p>
                            The account name will be visible to others.
                            You can change the account name later in the account settings.
                        </p>
                        <v-text-field v-model="newAccountName" ref="newAccountNameInput" label="Account name" hint="The name of your account or project" dense outlined @keyup.enter.prevent="createAccount"></v-text-field>
                        <v-btn :style="primaryButtonStyle" @click="createAccount">Create</v-btn>
                    </v-card-text>
                </template>
                <template v-if="step === 'select_account' && isViewReady">
                    <v-card-text>
                            <!-- <p>
                                Let's create an account and give it a name.
                            </p>
                            <p>
                                The account name will be visible to others.
                                You can change the account name later in the account settings.
                            </p>
                            <v-text-field v-model="newAccountName" ref="newAccountNameInput" label="Account name" hint="The name of your account or project" dense outlined @keyup.enter.prevent="createAccount"></v-text-field>
                            <v-btn :style="primaryButtonStyle" @click="createAccount">Create</v-btn> -->
                            <p class="mb-0">
                                Select an account to continue:
                            </p>
                            <v-list dense>
                                <v-list-item v-for="(account, idx) in accountList" :key="idx" @click="selectAccount(account.id)" class="px-2">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ account.name }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        <!-- <p v-if="Array.isArray(accountList) && accountList.length > 0">
                            You already have <router-link :to="{ name: 'user-account-list' }">{{accountList.length}} accounts</router-link>. Go to the <router-link :to="{ name: 'user-account-list' }">account list</router-link> to select an account or create a new account.
                        </p> -->
                    </v-card-text>
                </template>
            </v-card>
            <p class="mb-15"></p>
            <v-alert type="error" v-if="serverError">
                An error occurred while processing your request. Please try again or contact customer support.
            </v-alert>
            <v-alert type="error" v-if="requestError">
                We could not send a verification email. Please try again. If the problem continues, try with a different email address or contact customer support.
            </v-alert>
            <v-alert type="error" v-if="forbiddenError">
                The link is expired or invalid. Check that the email you entered is correct and try again.
            </v-alert>
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
</style>

<script>
// import { toText } from '@libertyio/time-util-js';
import { mapState, mapGetters } from 'vuex';
import { isValidProductLookupKey } from '@/sdk/input';
// import BrandList from '@/components/BrandList.vue';

export default {
    components: {
        // BrandList,
    },
    data: () => ({
        isViewReady: false,
        steps: ['setup_loginfront', 'create_account', 'select_account'],
        step: 'setup_loginfront',
        product: null,
        submitTimestamp: null,
        redirect: null,
        newAccountName: null,
        accountList: null,
        // older ones, check if we're using
        interactionId: null,
        verificationExpires: null,
        serverError: false,
        serverErrorTimeout: null,
        requestError: false,
        requestErrorTimeout: null,
        inputError: null,
        inputErrorTimeout: null,
        forbiddenError: null,
        forbiddenErrorTimeout: null,
        registered: false,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
        // mainWebsiteURL() {
        //     return process.env.VUE_APP_MAIN_WEBSITE_URL ?? 'https://brandprofile.org';
        // },
        progress() {
            if (this.steps.length === 0) {
                return 0;
            }
            const idx = this.steps.findIndex((value) => value === this.step);
            return Math.ceil(((idx + 1) * 100) / this.steps.length);
        },
    },
    watch: {
        focus() {
            if (this.step === 'create_account') {
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('newAccountNameInput'); }, 1);
                });
            }
        },
        /*
        $route(newValue) {
            if (newValue.query.step !== this.step) {
                this.step = newValue.query.step;
                if (newValue.query.step === 'start') {
                    this.$nextTick(() => {
                        setTimeout(() => { this.activate('usernameInput'); }, 1);
                    });
                }
            }
        },
        */
    },
    methods: {
        resetErrors() {
            this.serverError = false;
            if (this.serverErrorTimeout) {
                clearTimeout(this.serverErrorTimeout);
                this.serverErrorTimeout = null;
            }
            this.requestError = false;
            if (this.requestErrorTimeout) {
                clearTimeout(this.requestErrorTimeout);
                this.requestErrorTimeout = null;
            }
            this.inputError = null;
            if (this.inputErrorTimeout) {
                clearTimeout(this.inputErrorTimeout);
                this.inputErrorTimeout = null;
            }
            this.forbiddenError = false;
            if (this.forbiddenErrorTimeout) {
                clearTimeout(this.forbiddenErrorTimeout);
                this.forbiddenErrorTimeout = null;
            }
            this.redirect = null;
            this.verificationExpires = null;
        },
        async check() {
            try {
                this.resetErrors();
                this.$store.commit('loading', { checkUserSetup: true });
                await this.$store.dispatch('refresh');

                // check for user who returned from setting up authentication
                if (this.$route.query.loginfront) {
                    // TODO: should we get any other values in the response from checkLogin when we start it with the 'setup' intent?
                    const { isAuthenticated } = await this.$client.main().authn.checkLogin({ token: this.$route.query.loginfront });
                    if (isAuthenticated) {
                        await this.$store.dispatch('refresh', { progressIndicator: true });
                    }

                    const query = { ...this.$route.query, t: Date.now() };
                    delete query.loginfront;
                    this.$router.replace({ name: 'profile-setup', query });

                    if (!isAuthenticated) {
                        this.serverError = true; // TODO: in addition to server error message, we also need to show a link to try and login again
                        this.isViewReady = true;
                        return;
                    }
                } else {
                    await this.$store.dispatch('refresh');
                }

                // check user configured authentication
                if (!this.user.is_registered_loginfront) {
                    this.step = 'setup_loginfront';
                    this.isViewReady = true;
                    const query = { ...this.$route.query, step: 'setup_loginfront', t: Date.now() };
                    this.$router.replace({ name: 'profile-setup', query });
                    return;
                }

                // check user has at least one account
                await this.loadAccountList();
                if (!Array.isArray(this.accountList) || this.accountList.length === 0) {
                    if (this.step !== 'create_account') {
                        this.step = 'create_account';
                        this.isViewReady = true;
                        const query = { ...this.$route.query, step: 'create_account', t: Date.now() };
                        this.$router.replace({ name: 'profile-setup', query });
                    }
                    // automatically create the account, so it's one less step for a new user to do... they can always change the account name later
                    this.newAccountName = this.user.email;
                    this.createAccount();
                    this.$nextTick(() => {
                        setTimeout(() => { this.activate('newAccountNameInput'); }, 1);
                    });
                    return;
                }

                this.step = 'select_account';
                this.isViewReady = true;
                const query = { ...this.$route.query, step: 'select_account', t: Date.now() };
                this.$router.replace({ name: 'profile-setup', query });
                this.isViewReady = true;
            } catch (err) {
                console.error('failed to check user setuup', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.resetErrors();
                        this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { checkUserSetup: false });
                this.isViewReady = true;
            }
        },
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        async loadAccountList() {
            try {
                this.$store.commit('loading', { loadAccountList: true });
                const result = await this.$client.user(this.user.id).self.getAccountList();
                this.accountList = result.list;
            } catch (err) {
                console.error('failed to load account list', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.resetErrors();
                        this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { loadAccountList: false });
            }
        },
        async setupLoginFront() {
            try {
                this.$store.commit('loading', { setupLoginFront: true });
                const { redirect } = await this.$client.main().authn.prefsRedirect({
                    intent: 'setup',
                });
                if (typeof redirect === 'string') {
                    // since we only redirect the user in response to a button action, we
                    // use `push` so that user can return to this same page and try again
                    // if they use the back button
                    if (typeof window.location.push === 'function') {
                        window.location.push(redirect);
                    } else {
                        // TODO: also show the link on the page for user to click
                        window.location.href = redirect;
                    }
                    return;
                }
                console.error('setupLoginFront received unexpected response from server');
                this.serverError = true;
                this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
            } catch (err) {
                console.error('setupLoginFront failed', err);
                this.serverError = true;
                this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
            } finally {
                this.$store.commit('loading', { setupLoginFront: false });
            }
        },
        async createAccount() {
            if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitTimestamp = Date.now();
            if (!this.newAccountName) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Please enter an account name' });
                return;
            }
            try {
                this.$store.commit('loading', { createAccount: true });
                const response = await this.$client.user(this.user.id).self.createAccount({
                    name: this.newAccountName,
                });
                if (response.isCreated && response.id) {
                    this.$router.push({ name: 'account-setup', params: { accountId: response.id } });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create new account' });
                }
            } catch (err) {
                console.error('createAccount failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create new account' });
            } finally {
                this.$store.commit('loading', { createAccount: false });
            }
        },
        async selectAccount(id) {
            this.$router.push({ name: 'account-setup', params: { accountId: id } });
        },
    },
    mounted() {
        // if user arrives with `product=essential|business|enterprise`,
        // pass this value along to the next step; the `product` value will be validated
        // at Unicorn Springs and if it's invalid user will see a choice of products to
        // select at that time; here we do just a valid-characters check on the value
        if (this.$route.query.product && isValidProductLookupKey(this.$route.query.product)) {
            this.product = this.$route.query.product;
        }

        this.check();
        /*
        // if user arrives with `email` and `step=verify_email`, call signup to check
        // the email address, if it's not verified we will redirect to EtherLink, and
        // eventually return here with a verified address or cancel the interaction
        if (this.$route.query.step === 'verify_email' && this.$route.query.email) {
            this.step = 'verify_email';
            this.username = this.$route.query.email;
            this.signup();
            return;
        }

        // if user arrives with `email` and `step=display_name`, show the email address and
        // ask user for their display name
        if (this.$route.query.step === 'display_name' && this.$route.query.email) {
            this.step = 'display_name';
            this.username = this.$route.query.email;
            this.isViewReady = true;
            this.$nextTick(() => {
                setTimeout(() => { this.activate('displayNameInput'); }, 1);
            });
            return;
        }

        // if user arrives with `email` and `step=setup_loginfront`, check that user is authenticated
        // and has the same email address and show that step, otherwise start over
        if (this.$route.query.step === 'setup_loginfront' && this.$route.query.email) {
            if (this.session.isAuthenticated && this.user.email === this.$route.query.email) {
                this.step = 'setup_loginfront';
                this.username = this.$route.query.email;
                this.isViewReady = true;
                return;
            }
        }

        // the first time user arrives on this page, there would not be an interaction id in the query,
        // but there could be an email address to pre-fill;
        // the second time the user arrives on this page (after email verification is completed), there
        // would be an interaction id also;
        // we only auto-submit if there's an interaction id meaning the user got here via one of our own links
        this.interactionId = this.$route.query.i;
        this.username = this.$route.query.email ?? '';
        if (this.interactionId && this.username) {
            this.signup();
            return;
        }

        this.isViewReady = true;
        this.$nextTick(() => {
            setTimeout(() => { this.activate('usernameInput'); }, 1);
        });
        // TODO: load brand info if we have this.$route.query.brand  (is probably etherlink brand for signup)
        // if (this.username) {
        //     this.search();
        // }
        */
    },
};
</script>
